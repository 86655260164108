import { isArray, isString } from 'lodash-es';
import path from 'path-browserify';
import { dispatchClick } from './click';
import { downloadBlob } from './download';

export const renameFile = (fp: string, name: string) => {
  const fh = window.MeApi?.fileHelper;
  if (fh && fp) {
    return fh.rename(fp, name);
  }
};
export const saveFile = async (fp: string, content: string | ArrayBuffer, saveAs = false, opts = {}) => {
  const fh = window.MeApi?.fileHelper;
  if (fh) {
    if (content instanceof ArrayBuffer) {
      content = new Uint8Array(content);
    }
    return fh.save(fp, content, {
      write: 'utf8',
      save: {
        defaultPath: fp,
        ...opts,
      },
      saveAs,
    });
  } else {
    if (content instanceof ArrayBuffer) {
      const blob = new Blob([content]);
      downloadBlob(fp, blob);
    } else if (isString(content)) {
      const file = new File([content], fp);
      downloadBlob(fp, file);
    } else {
      console.warn(['=> not support save file content', { fp, content }]);
    }
  }
};

/**
 * 取消选择错误
 *
 * @date 30/08/2022
 * @export
 * @class CancelOpenFile
 * @extends {Error}
 */
export class CancelOpenFile extends Error {}

/**
 * 打开选择文件
 *
 * @date 11/02/2022
 * @param {string} [accept]
 * @param {number} [type] // 移动端打开文件选择框的类型 0：拍照 1：相册 2：系统文件
 * @return {*}
 */
export const openFileDialog = (accept?: string[] | string, type?: number): Promise<File | string> => {
  const fh = window.MeApi?.fileHelper;
  if (fh) {
    return fh.openDialog({
      filters: [{ name: 'file', extensions: accept }],
      type,
    });
  }

  const fileInput = () => {
    let input: HTMLInputElement | null = document.querySelector('#fileDialog');
    if (!(input instanceof HTMLInputElement)) {
      input = document.createElement('input');
      input.id = 'fileDialog';
      input.style.position = 'absolute';
      input.style.visibility = 'hidden';
      input.style.top = '-100%';
      input.setAttribute('type', 'file');
    }
    return input;
  };

  return new Promise<File>((resolve, reject) => {
    let timer: any = null;
    let isOpenDialog = false;
    const input = fileInput();
    const reset = () => {
      input.value = '';
    };
    if (accept) {
      if (isArray(accept)) {
        accept = `.${accept.join(',.')}`;
      }
      input.setAttribute('accept', accept);
    }
    const handleClick = () => {
      isOpenDialog = true;
    };
    const handleChange = (event?: any) => {
      clearTimeout(timer);
      if (event) {
        const files: File[] = event.target.files;
        if (files.length > 0) {
          resolve(files[0] as any);
          reset();
          return;
        }
      }
      reset();
      reject(new CancelOpenFile('cancel open file.'));
    };
    const handleFocus = () => {
      // handleChange();
      if (isOpenDialog) {
        isOpenDialog = false;
        clearTimeout(timer);
        timer = setTimeout(() => {
          handleChange();
        }, 1000);
      }
    };
    input.onchange = handleChange;
    input.onclick = handleClick;
    // input.addEventListener('click', handleClick, { once: true });
    // input.addEventListener('change', handleChange, { once: true });
    window.addEventListener('focus', handleFocus, { once: true });
    dispatchClick(input);
  });
};

export const basename = (filePath: string, ext?: string) => {
  if (window.MeApi?.path) {
    return window.MeApi?.path.basename(filePath, ext);
  }
  return path.basename(filePath, ext);
};

const reviver = (_: any, value: any) => {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map' && value.value instanceof Array) {
      return new Map(value.value);
    }
  }
  return value;
};

const readFile = (data: File | Blob | string, type = 'text') => {
  if (isString(data)) {
    const fh = window.MeApi?.fileHelper;
    if (fh) {
      if (type === 'text') {
        return fh.read2Str(data);
      }
      return fh.read2Buffer(data);
    }
    return Promise.reject(`read file error. path: ${data}`);
  }
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as any);
    };
    reader.onerror = () => {
      reject(`read file error. path: ${data}`);
    };
    if (type === 'text') {
      reader.readAsText(data);
    } else {
      reader.readAsArrayBuffer(data);
    }
  });
};

export const readFileToText = async (file: File | string): Promise<string> => {
  if (isString(file)) {
    const fh = window.MeApi?.fileHelper;
    if (fh) {
      // node由于读取文件不同编码处理得不是很好，所以改为读取成buffer，再转成blob由浏览器FileReader读取，兼容性高
      const buffer = await fh.read2Buffer(file);
      const blob = new Blob([buffer]);
      return readFile(blob) as Promise<string>;
    }
  }
  return readFile(file) as Promise<string>;
};
export const readFileToJson = (file: File | string): Promise<any> => {
  if (isString(file)) {
    const fh = window.MeApi?.fileHelper;
    if (fh) {
      return fh.read2Str(file).then((text: string) => JSON.parse(text, reviver));
    }
  }
  return readFileToText(file).then((text) => {
    return JSON.parse(text, reviver);
  });
};
